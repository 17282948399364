import React, { FC, HTMLProps, ReactNode, useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../UI/shadcn/select";
import Body2 from "../../Text/Body/Body2";
import { cn } from "../../../../lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/tooltip";
import Body1 from "../../Text/Body/Body1";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Caption1 from "../../Text/Caption/Caption1";

type FormFieldSelectChildren = {
  id: string;
  name: string;
  icon?: any;
};

interface FormFieldSelectProps extends HTMLProps<HTMLSelectElement> {
  /**
   * The placeholder for the select
   */
  placeholder: string;
  /**
   * The label for the select (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The content inside the select element
   */
  children: any[];
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;
  /**
   * Value of the select
   */
  value: string;
  /**
   * The default value of the select
   */
  defaultValue?: string;
  /**
   * optional styling for the trigger
   */
  className?: string;
  /**
   * Tool tip trigger text
   */
  toolTipTrigger?: string;
  /**
   * Tool tip content text
   */
  toolTipContent?: string;
  /**
   * Custom styling for the container
   */
  containerClassName?: string;
  /**
   * Custom styling for the label
   */
  labelClassName?: string;
}

/**
 * @param children: Takes an array of strings as it's children and displays them as select options.
 */
const FormFieldSelect: FC<FormFieldSelectProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled = false,
  error = false,
  children,
  toolTipTrigger,
  toolTipContent = "",
  inputChange,
  className,
  value,
  containerClassName,
  labelClassName,
  ...props
}: FormFieldSelectProps) => {
  const mode = disabled
    ? "border border-neutral-60 bg-neutral-90 focus:outline-none"
    : error
    ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
    : "border border-secondary-80 hover:border-secondary-50 focus:outline focus:outline-primary-80 focus:border-primary-80";

  return (
    <div
      className={cn("flex flex-col w-full text-black m-0", containerClassName)}
    >
      <div
        className={cn(
          `px-3 text-xs font-medium`,
          error && !disabled && "text-error-10",
          toolTipContent !== "" && "flex flex-row gap-1",
          labelClassName
        )}
      >
        {label}

        {toolTipContent !== "" && (
          <TooltipProvider delayDuration={300}>
            <Tooltip>
              <TooltipTrigger>
                <InfoOutlinedIcon
                  className="text-info-20"
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </TooltipTrigger>
              <TooltipContent className="max-w-[200px]">
                <Caption1 whiteSpace="normal">{toolTipContent}</Caption1>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className="relative flex flex-row items-center gap-2">
        <Select
          value={value}
          onValueChange={inputChange}
          disabled={disabled}
        >
          <SelectTrigger
            className={cn(
              mode,
              "w-full flex justify-between items-center rounded-xl bg-white h-[40px]",
              className
            )}
          >
            <SelectValue className="text-light" />
          </SelectTrigger>
          <SelectContent className="justify-start bg-white rounded-xl">
            {children.map((child: FormFieldSelectChildren, index: number) => {
              return (
                <SelectItem
                  key={child.id}
                  value={child.id.toString()}
                  className="w-full hover:bg-primary-95 focus-within:bg-primary-95"
                >
                  <div className="flex flex-row gap-1">
                    {child.icon && child.icon}
                    <Body2>{child.name}</Body2>
                  </div>
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && !disabled && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? "text-error-10" : "text-light"
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

export { FormFieldSelect };
