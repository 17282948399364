import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import { Column } from "react-table";
import BaseTable from "../../../UI/Table/Table";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import TablePagination from "../../../UI/Pagination/Pagination";
import {
  ThirdPartyContractorShiftsPaginatedQuery,
  useThirdPartyContractorByRegionIdQuery,
  useThirdPartyContractorShiftsPaginatedLazyQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useAllContractorShiftContext } from "../../../../context/AllContractorShiftContext";
import dayjs from "dayjs";
import { DatePicker } from "../../../UI/shadcn/Time/date-picker";
import { dayOfWeek } from "../../../../utils/dayOfWeek";
import { displayAlertError } from "../../../../app/globalSlice";
import { EPermission, isPermitted } from "../../../../utils/permissions";
import Tooltip from "../../../UI/Tooltip/Tooltip";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "../../../../components/UI/shadcn/dialog";
import { deleteShift } from "../../../../app/shiftSlice";

// List of options for action col
const options = [{ id: 1, text: "Create/View Notes" }];

// Type that is needed for action col cell
interface CellParam {
  row: {
    original: ThirdPartyContractorShiftsPaginatedQuery["thirdPartyContractorShiftsPaginated"]["shifts"][0];
  };
  rows: {
    original: ThirdPartyContractorShiftsPaginatedQuery["thirdPartyContractorShiftsPaginated"]["shifts"][0];
  }[];
}

const AllContractorShiftsReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, isLoading } = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { user } = useSelector((state: RootState) => state.auth);
  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/
  const { filter, setFilter } = useAllContractorShiftContext();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [shiftToDeleteId, setShiftToDeleteId] = useState<number | null>(null);

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [
    GetThirdPartyContractorShifts,
    {
      loading: loadingThirdPartyContractorShifts,
      data: dataThirdPartyContractorShifts,
    },
  ] = useThirdPartyContractorShiftsPaginatedLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      thirdPartyContractorShiftFilterInput: {
        page: filter.pagination.page,
        pageSize: filter.pagination.pageSize,
        regionId: filter.regionId ?? null,
        toDate: dayjs(filter.toDate)
          .hour(23)
          .minute(59)
          .format("YYYY-MM-DD HH:mm:ss"),
        fromDate: dayjs(filter.fromDate)
          .hour(0)
          .minute(0)
          .format("YYYY-MM-DD HH:mm:ss"),
        weekday: filter.dayOfWeek,
        contractorId: filter.contractorId,
      },
    },
    onCompleted: (data) => {
      const pageCount = Math.ceil(
        data.thirdPartyContractorShiftsPaginated.count / pageSize
      );
      setFilter((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          pageCount: pageCount,
        },
      }));
    },
  });

  const {
    loading: loadingThirdPartyContractors,
    data: dataThirdPartyContractors,
  } = useThirdPartyContractorByRegionIdQuery({
    variables: {
      regionId: filter.regionId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (
        data.thirdPartyContractorByRegionId.every(
          (contractor) => contractor.id !== filter.contractorId
        )
      ) {
        setFilter((prevState) => ({
          ...prevState,
          contractorId: 0,
        }));
      }
    },
  });

  /*** USE EFFECTS ***/
  useEffect(() => {
    handleApplyFilter();
  }, []);

  /*** UTILITY FUNCTIONS ***/
  // Handles the state for the pagination component
  function handleChangePagination(value: number) {
    setFilter((prevState) => ({
      ...prevState,
      pagination: {
        pageCount: prevState.pagination.pageCount,
        pageSize: prevState.pagination.pageSize,
        page: value - 1,
      },
    }));
  }

  async function handleApplyFilter() {
    await GetThirdPartyContractorShifts();
  }

  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const handleSelectedOption = (
    row: ThirdPartyContractorShiftsPaginatedQuery["thirdPartyContractorShiftsPaginated"]["shifts"][0],
    idx: number
  ) => {
    if (idx === 1) {
      // create/view notes
      navigate(`/staffing/shift-notes/${row.id}`);
    }
    if (idx === 2) {
      // edit
      navigate(`/staffing/shift/${row.id}`);
    }
    if (idx === 3) {
      // delete
      // disabled if it is past shift- Not added for now after DDM review
      // disabled if shift report has been reported
      // disabled if the shift is rejected- Not added for now after DDM review
      if (row.shiftReport?.id) {
        dispatch(displayAlertError("Cannot delete this shift"));
        return;
      }
      setOpenDeleteModal(true);
      setShiftToDeleteId(row.id);
    }
  };
  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const COLUMNS: Column<
    ThirdPartyContractorShiftsPaginatedQuery["thirdPartyContractorShiftsPaginated"]["shifts"][0]
  >[] = [
    {
      Header: "Id",
      id: "Id",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "Start Time",
      id: "startLocal",
      accessor: (d) => {
        return <div>{dayjs(d.startLocal).format("YYYY-MM-DD h:mma")}</div>;
      },
    },
    {
      Header: "End Time",
      id: "endLocal",
      accessor: (d) => {
        return <div>{dayjs(d.endLocal).format("YYYY-MM-DD h:mma")}</div>;
      },
    },
    {
      Header: "Shift Length",
      id: "shiftLenth",
      accessor: (d) => {
        return (
          <div>{`${dayjs(d.endLocal).diff(d.startLocal, "hour")} hours`}</div>
        );
      },
    },
    {
      Header: "Venue",
      id: "venue",
      accessor: (d) => {
        return <div>{d.venue ? d.venue.name : "No Location"}</div>;
      },
    },
    {
      Header: "Assigned To",
      id: "name",
      accessor: (d) => {
        return <div>{d.thirdPartyContractor!.name}</div>;
      },
    },
    {
      Header: "Email",
      id: "email",
      accessor: (d) => {
        return <div>{d.thirdPartyContractor!.email}</div>;
      },
    },
    {
      Header: "Rate",
      id: "rate",
      accessor: (d) => {
        return <div>{d.rate}</div>;
      },
    },
    {
      Header: "Attendance",
      id: "shiftStatus",
      accessor: (d) => {
        return (
          <div>{d.shiftStatus ? d.shiftStatus.value : "Missing status"}</div>
        );
      },
    },
    {
      Header: "",
      id: "action",
      Cell: (d: CellParam) => {
        let actionsOptions = [...options];
        // Edit Shift option if shift is rejected and unreported
        const rejectedAndUnreportedShift =
          d.row.original.shiftStatusId === 3 && !d.row.original.shiftReport;

        const acceptedAndReportedShift =
          d.row.original.shiftStatusId === 2 && d.row.original.shiftReport;

        const paidShift = d.row.original.shiftPaymentStatusId === 4;

        if (!rejectedAndUnreportedShift)
          actionsOptions.push({ id: 2, text: "Edit Shift" });

        if (!rejectedAndUnreportedShift && !acceptedAndReportedShift)
          actionsOptions.push({ id: 3, text: "Delete Shift" });

        // if shift is paid, remove edit and delete option. Only add notes option
        if (paidShift) {
          actionsOptions = [...options];
        }

        // setting permissionx
        const isPermissionGranted = isPermitted(user?.permission, {
          permission: EPermission["EDIT_SHIFT"],
          regionId: d.row.original.regionId,
        });

        return (
          <div className="flex flex-row items-center justify-center">
            <ActionsCell
              row={d.row.original}
              options={actionsOptions}
              handleSelectedOption={handleSelectedOption}
              disabled={!isPermissionGranted}
            />
            <div className="p-2">
              {d.row.original.shiftNotes?.length === 0 ? (
                <Tooltip
                  hoverText="No Note"
                  trigger={<PostAddOutlinedIcon />}
                />
              ) : (
                <Tooltip
                  hoverText="Notes Added"
                  trigger={<GradingOutlinedIcon className="text-primary-30" />}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  // Constants for the table
  const columns = useMemo(() => COLUMNS, [user]);
  const tableData = useMemo(() => {
    if (dataThirdPartyContractorShifts)
      return dataThirdPartyContractorShifts.thirdPartyContractorShiftsPaginated
        .shifts;
  }, [dataThirdPartyContractorShifts]);

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog
        open={
          loadingThirdPartyContractorShifts ||
          loadingThirdPartyContractors ||
          isLoading
        }
      />
      <Dialog
        open={openDeleteModal}
        onOpenChange={setOpenDeleteModal}
      >
        <DialogContent>
          <DialogHeader>Delete Shift</DialogHeader>
          <DialogDescription>
            Are you sure you want to delete this shift?
          </DialogDescription>
          <DialogFooter>
            <DialogClose>
              <Button
                variant="secondary"
                onClick={() => {
                  if (shiftToDeleteId) {
                    dispatch(deleteShift(shiftToDeleteId));
                  }
                }}
              >
                Delete
              </Button>
            </DialogClose>
            <DialogClose>
              <Button variant="primary">Cancel</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="flex flex-row items-center justify-between w-full">
        <Headline1Variable>All Contractor Shifts</Headline1Variable>
      </div>
      <div className="flex flex-col gap-2 w-fit">
        <div className="grid w-full grid-cols-3 gap-4">
          <FormFieldSelect
            value={filter.regionId.toString()}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
          <FormFieldSelect
            value={filter.contractorId.toString()}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                contractorId: +value,
              }));
            }}
            name="thirdPartyContractorId"
            id="thirdPartyContractorId"
            label="Third Party Contractor"
            placeholder="Select Region"
          >
            {dataThirdPartyContractors === undefined
              ? [{ id: 0, name: "None" }]
              : [
                  ...[{ id: 0, name: "All" }],
                  ...dataThirdPartyContractors.thirdPartyContractorByRegionId,
                ]}
          </FormFieldSelect>
          <FormFieldSelect
            key={filter.dayOfWeek}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                dayOfWeek: +value,
              }));
            }}
            label={"Day"}
            placeholder="Select Day of Week"
            value={filter.dayOfWeek.toString()}
          >
            {[{ id: -1, name: "All" }, ...dayOfWeek]}
          </FormFieldSelect>
        </div>
        <div className="grid items-end w-full grid-cols-3 gap-4">
          <DatePicker
            label="From Date"
            date={filter.fromDate}
            setDate={(date) => {
              if (date) {
                setFilter((prevState) => ({
                  ...prevState,
                  fromDate: date,
                }));
              }
            }}
          />
          <DatePicker
            label="To Date"
            date={filter.toDate}
            setDate={(date) => {
              if (date) {
                setFilter((prevState) => ({
                  ...prevState,
                  toDate: date,
                }));
              }
            }}
          />
          <Button
            variant="primary"
            onClick={handleApplyFilter}
            className="w-full h-[40px]"
          >
            Apply
          </Button>
        </div>
      </div>
      {tableData && (
        <div className="flex flex-col gap-2">
          <BaseTable
            columns={columns}
            data={tableData}
          />
          {filter.pagination && (
            <div className="flex justify-end mt-4">
              <TablePagination
                page={filter.pagination.page || 0}
                pageCount={filter.pagination.pageCount || 0}
                onChange={handleChangePagination}
              />
            </div>
          )}
        </div>
      )}
    </main>
  );
};

export default AllContractorShiftsReport;
