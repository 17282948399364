import { gql } from "@apollo/client";

const GET_DATE_GROUPED_SHIFTS = gql`
  query AllShiftsGrouped($shiftFilter: ShiftFilter) {
    allShifts(shiftFilter: $shiftFilter) {
      date
      shifts {
        id
        endLocal
        startLocal
        rate
        userId
        venueId
        staffTypeId
        regionId
        shiftStatusId
        shiftPaymentStatusId
        region {
          id
          name
        }
        venue {
          id
          name
        }
        staffType {
          id
          name
        }
        shiftStatus {
          id
          value
        }
        user {
          id
          firstName
          lastName
          email
        }
        shiftNotes {
          id
          note
        }
        shiftReport {
          id
          shiftReportStatusId
          userId
          contents
          shiftId
          departure
          arrival
          createdAt
        }
      }
    }
  }
`;

const CREATE_SHIFT = gql`
  mutation CreateShift($createShiftInput: CreateShiftInput) {
    createShift(createShiftInput: $createShiftInput) {
      code
      message
      shift {
        id
      }
      success
    }
  }
`;

const UPDATE_SHIFT = gql`
  mutation UpdateShift($updateShiftInput: UpdateShiftInput) {
    updateShift(updateShiftInput: $updateShiftInput) {
      code
      message
      success
      shift {
        id
      }
    }
  }
`;

const DELETE_SHIFT = gql`
  mutation ShiftDelete($shiftDeleteId: Int!) {
    shiftDelete(id: $shiftDeleteId) {
      code
      message
      shift {
        id
      }
      success
    }
  }
`;

const GET_OPEN_SHIFTS = gql`
  query OpenShifts($openShiftsFilter: OpenShiftFilter) {
    openShifts(openShiftsFilter: $openShiftsFilter) {
      countRequestedShifts
      league {
        name
      }
      openShift {
        id
        staffType {
          name
        }
        startLocal
        endLocal
        hours
        shiftNotes {
          id
          note
        }
      }
      location {
        id
        name
      }
    }
  }
`;

const ASSIGN_SHIFT = gql`
  mutation ShiftAssign($assignShiftInput: AssignShiftInput) {
    shiftAssign(assignShiftInput: $assignShiftInput) {
      code
      message
      success
      shift {
        id
      }
    }
  }
`;

const MY_SHIFT = gql`
  query MyShifts {
    myShifts
  }
`;

const UPDATE_SHIFT_STATUS = gql`
  mutation ShiftStatusUpdate($shiftId: Int!, $shiftStatusId: Int!) {
    shiftStatusUpdate(shiftId: $shiftId, shiftStatusId: $shiftStatusId) {
      code
      message
      shiftStatus {
        id
      }
      success
    }
  }
`;

const GET_SHIFTS_NOT_PAID = gql`
  query ShiftsNotPaid {
    shiftsNotPaid
  }
`;

const UPDATE_SHIFTS_PAID = gql`
  mutation ShiftsPaidUpdate($shiftsId: [Int!]!) {
    shiftsPaidUpdate(shiftsId: $shiftsId) {
      code
      message
      success
    }
  }
`;

const GET_SHIFTS_BEFORE_CUTOFF_DATE = gql`
  query ShiftsBeforeCutoffDate(
    $date: String
    $shiftPaymentStatusId: String
    $regionIds: [Int]
    $regionId: String
    $page: Int
    $pageSize: Int
  ) {
    shiftsBeforeCutoffDate(
      date: $date
      shiftPaymentStatusId: $shiftPaymentStatusId
      regionIds: $regionIds
      regionId: $regionId
      page: $page
      pageSize: $pageSize
    ) {
      shifts {
        id
        startLocal
        endLocal
        hours
        amount
        contractorId
        isVisible
        venueId
        rate
        region {
          id
          name
        }
        shiftReport {
          shiftReportStatusId
        }
        shiftStatus {
          id
          value
        }
        venue {
          id
          name
        }
        weekday
        user {
          id
          firstName
          lastName
        }
        staffType {
          id
          name
        }
        payrollBatch {
          payrollBatchStatus {
            id
            name
          }
        }
      }
      count
    }
  }
`;

const GET_SHIFT_PAYMENT_STATUSES = gql`
  query ShiftPaymentStatuses {
    shiftPaymentStatuses {
      id
      name
    }
  }
`;

const SHIFTS_READY_FOR_PAYMENT = gql`
  mutation ShiftsReadyForPayment($shiftsId: [Int!]!) {
    shiftsReadyForPayment(shiftsId: $shiftsId) {
      code
      message
      success
    }
  }
`;

const SHIFTS_MARKED_AS_PAID = gql`
  mutation ShiftsMarkedAsPaid($shiftsId: [Int!]!, $payrollBatchId: String) {
    shiftsMarkedAsPaid(shiftsId: $shiftsId, payrollBatchId: $payrollBatchId) {
      code
      success
      message
    }
  }
`;

const SHIFTS_SUMMARY = gql`
  query ShiftsSummary($shiftsId: [Int!]!) {
    shiftsSummary(shiftsId: $shiftsId) {
      code
      message
      shiftSummary {
        numOfShifts
        totalAmount
        totalHours
      }
      success
    }
  }
`;

gql`
  query ShiftById($id: Int!) {
    shiftById(id: $id) {
      id
      venueId
      regionId
      startLocal
      endLocal
      staffTypeId
      userId
      contractorId
      rate
      isShiftOvernight
      region {
        name
      }
      shiftReport {
        id
        shiftReportStatusId
      }
    }
  }
`;

gql`
  query ThirdPartyContractorShiftsPaginated(
    $thirdPartyContractorShiftFilterInput: ThirdPartyContractorShiftFilter!
  ) {
    thirdPartyContractorShiftsPaginated(
      thirdPartyContractorShiftFilterInput: $thirdPartyContractorShiftFilterInput
    ) {
      count
      shifts {
        id
        startLocal
        endLocal
        venue {
          name
        }
        thirdPartyContractor {
          name
          email
        }
        regionId
        rate
        shiftStatus {
          value
        }
        shiftStatusId
        shiftPaymentStatusId
        shiftReport {
          id
        }
        shiftNotes {
          id
        }
      }
    }
  }
`;

export {
  CREATE_SHIFT,
  UPDATE_SHIFT,
  DELETE_SHIFT,
  ASSIGN_SHIFT,
  GET_SHIFTS_NOT_PAID,
  UPDATE_SHIFTS_PAID,
  GET_DATE_GROUPED_SHIFTS,
  GET_OPEN_SHIFTS,
  MY_SHIFT,
  UPDATE_SHIFT_STATUS,
  GET_SHIFTS_BEFORE_CUTOFF_DATE,
  GET_SHIFT_PAYMENT_STATUSES,
  SHIFTS_READY_FOR_PAYMENT,
  SHIFTS_MARKED_AS_PAID,
  SHIFTS_SUMMARY,
};
