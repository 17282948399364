import { AllContractorShiftContextProvider } from "./AllContractorShiftContext";
import { EmailToolMarketingContextProvider } from "./EmailToolMarketingContext";
import { ImageManagerContextProvider } from "./ImageMangerContext";
import { StaffTypeContextProvider } from "./StaffTypeContext";

export const WrapperProvider = ({ children }: any) => {
  return (
    <AllContractorShiftContextProvider>
      <EmailToolMarketingContextProvider>
        <StaffTypeContextProvider>
          <ImageManagerContextProvider>{children}</ImageManagerContextProvider>
        </StaffTypeContextProvider>
      </EmailToolMarketingContextProvider>
    </AllContractorShiftContextProvider>
  );
};
