import { gql } from "@apollo/client";

const GET_DIVISIONS = gql`
  query Divisions($divisionFilters: DivisionFilters) {
    divisions(divisionFilters: $divisionFilters) {
      id
      name
      startDate
      endDate
      weeksOfPlayoffs
      playoff {
        id
      }
      exceptionDates
      numberOfWeeks
      vmName
      vmColor
      session {
        id
        dayOfWeek
        startDate
        league {
          name
        }
      }
      teams {
        id
        name
      }
      latestSchedule {
        id
        regularGames {
          homeTeam {
            id
          }
          awayTeam {
            id
          }
        }
      }
    }
  }
`;

const CREATE_DIVISION = gql`
  mutation DivisionCreate($divisionArgs: CreateDivisionInput!) {
    divisionCreate(divisionArgs: $divisionArgs) {
      code
      success
      message
      division {
        id
        weeksOfPlayoffs
        vmName
        vmColor
        teams {
          id
        }
        startDate
        endDate
        name
        numberOfWeeks
        playoff {
          id
        }
        exceptionDates
      }
    }
  }
`;

const UPDATE_DIVISION = gql`
  mutation DivisionUpdate($divisionArgs: UpdateDivisionInput!) {
    divisionUpdate(divisionArgs: $divisionArgs) {
      code
      success
      message
      division {
        id
        weeksOfPlayoffs
        vmName
        vmColor
        teams {
          id
        }
        startDate
        endDate
        name
        numberOfWeeks
        playoff {
          id
        }
        exceptionDates
      }
    }
  }
`;

const GET_DIVISION_BY_ID = gql`
  query DivisionById($divisionId: Int!) {
    division(id: $divisionId) {
      id
      name
      vmName
      vmColor
      session {
        id
        dayOfWeek
        startDate
        league {
          name
          gameDuration
          gameTimeslot
          gamesPerOccurrence
        }
        divisions {
          id
          name
          vmColor
          teams {
            id
            name
            registration {
              regQuestionChoice {
                regSkillChoice {
                  name
                }
              }
            }
          }
        }
        teams {
          id
          name
          registration {
            regQuestionChoice {
              regSkillChoice {
                name
              }
            }
          }
        }
        registrationBatch {
          name
        }
      }
      teams {
        id
        name
        registration {
          regQuestionChoice {
            regSkillChoice {
              name
            }
          }
        }
      }
      startDate
      endDate
      exceptionDates
      numberOfWeeks
      weeksOfPlayoffs
      latestSchedule {
        id
        scheduleStatus {
          id
        }
        regularGames {
          homeTeam {
            id
          }
          awayTeam {
            id
          }
        }
      }
    }
  }
`;

const GET_DIVISION_SCHEDULE_BY_ID = gql`
  query DivisionByScheduleId($divisionId: Int!) {
    division(id: $divisionId) {
      id
      name
      vmName
      vmColor
      session {
        id
        dayOfWeek
        startDate
        league {
          name
          gameDuration
          gameTimeslot
          gamesPerOccurrence
          sportFormat {
            region {
              id
              country {
                countryCode
              }
            }
            scoringFormat {
              scoringType {
                name
                scoringFor
                scoringAgainst
                scoringForAbbreviation
                scoringAgainstAbbreviation
              }
            }
          }
        }
        divisions {
          id
          name
          vmColor
          teams {
            id
            name
          }
        }
        teams {
          id
          name
        }
        registrationBatch {
          name
        }
      }
      teams {
        id
        name
        regSeasonStanding
      }
      startDate
      endDate
      exceptionDates
      numberOfWeeks
      weeksOfPlayoffs
      latestSchedule {
        id
        scheduleStatus {
          id
          scheduleStatusName
        }
        lastModifiedAt
        lastModifiedBy {
          id
          firstName
          lastName
        }
        regularGames {
          id
          startDateTimeLocal
          homeScore
          awayScore
          countHomeScore
          countAwayScore
          isVisible
          week
          isWinByDefault
          playoffGameNumber
          venue {
            id
            name
          }
          homeTeam {
            id
            name
          }
          awayTeam {
            id
            name
          }
          gameType {
            id
            gameTypeName
          }
          gameStatus {
            id
            name
          }
          isDeleted
        }
        playoffGames {
          id
          startDateTimeLocal
          homeScore
          awayScore
          countHomeScore
          countAwayScore
          isVisible
          week
          isWinByDefault
          playoffGameNumber
          venue {
            id
            name
          }
          homeTeam {
            id
            name
          }
          awayTeam {
            id
            name
          }
          gameType {
            id
            gameTypeName
          }
          gamePlayoffMatchup {
            id
            gameId
            homeTeamType
            homeTeamValue
            awayTeamType
            awayTeamValue
          }
          gameStatus {
            id
            name
          }
        }
      }
      previousSchedules {
        id
        lastModifiedAt
        lastModifiedBy {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

const DELETE_DIVISION = gql`
  mutation DivisionDelete($divisionDeleteId: Int!) {
    divisionDelete(id: $divisionDeleteId) {
      code
      success
      message
    }
  }
`;

const UPDATE_RESEED_DIVISIONS = gql`
  mutation reseedDivisions($reseedDivisionInput: ReseedDivisionInput!) {
    reseedDivisions(reseedDivisionInput: $reseedDivisionInput) {
      code
      success
      message
    }
  }
`;

const REMOVE_DIVISION_TEAM = gql`
  mutation DivisionRemoveTeam($divisionId: Int!, $teamId: Int!) {
    divisionRemoveTeam(divisionId: $divisionId, teamId: $teamId) {
      code
      success
      message
    }
  }
`;

const GET_DIVISION_STANDINGS = gql`
  query DivisionStandings($divisionId: Int!) {
    divisionStandings(divisionId: $divisionId) {
      standings {
        defaultLosses
        gamesPlayed
        losses
        pointsTotal
        scoringAgainst
        scoringDifference
        scoringFor
        ties
        wins
      }
      teamId
      teamName
    }
  }
`;

export {
  GET_DIVISIONS,
  CREATE_DIVISION,
  GET_DIVISION_BY_ID,
  UPDATE_DIVISION,
  DELETE_DIVISION,
  GET_DIVISION_SCHEDULE_BY_ID,
  UPDATE_RESEED_DIVISIONS,
  REMOVE_DIVISION_TEAM,
  GET_DIVISION_STANDINGS,
};
