import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./CreateSessions.module.css";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {
  getLeagues,
  getRegSkillChoices,
  getRegistrationBatch,
  getSports,
} from "../../app/venueMasterSlice";
import { RegistrationBatch } from "../../types/types";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { z } from "zod";
import FormField from "../UI/FormField/FormField";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import dayjs from "dayjs";
import TextArea from "../UI/FormField/TextArea/TextArea";
import Button from "../UI/Button/Button";
import Alert from "../UI/Alerts/Alert";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Card from "../UI/Card/Card";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import { FormFieldSelectMulti } from "../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import FilterChip from "../UI/Input/FilterChip";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import {
  ListAllOperations,
  useSessionCreateMutation,
  useSessionQuery,
  useSessionUpdateMutation,
} from "../../generated/graphql";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";

const sessionSchema = z
  .object({
    id: z.number().optional(),
    dayOfWeek: z.number(),
    endDate: z.string(),
    exceptionDates: z.array(z.string()),
    leagueId: z.number().refine((value) => value !== 0, {
      message: "You must select a league",
      path: ["leagueId"],
    }),
    priceForTeam: z.number(),
    priceForIndy: z.number(),
    maxCapacity: z.number(),
    setCapacity: z.number(),
    indyTeams: z.number(),
    registrationBatchId: z.number().refine((value) => value !== 0, {
      message: "You must select a season",
      path: ["registrationBatchId"],
    }),
    sessionNotes: z.string(),
    startDate: z.string(),
    startTimes: z.string(),
    numberOfWeeks: z.number(),
    weeksOfPlayoffs: z.number(),
    venues: z.array(z.number()).refine(
      (value) => {
        return value.length > 0;
      },
      {
        message: "You must select at least 1 venue",
        path: ["venues"],
      }
    ),
    regSkillChoices: z.array(z.number()).refine(
      (value) => {
        return value.length > 0;
      },
      {
        message: "You must select at least 1 reg skill choice",
        path: ["regSkillChoices"],
      }
    ),
  })
  .refine((data) => data.dayOfWeek === dayjs(data.startDate).day(), {
    message: "Day of week is not the same as start date.",
    path: ["startDate"], // Pointing out which field is invalid
  })
  .refine((data) => data.dayOfWeek === dayjs(data.endDate).day(), {
    message: "Day of week is not the same as end date.",
    path: ["endDate"], // Pointing out which field is invalid
  }) // Validate the exception dates are within the start and end date
  .refine(
    (data) => {
      const startDate = dayjs(data.startDate);
      const endDate = dayjs(data.endDate);
      const exceptionDates = data.exceptionDates.map((date: string) =>
        dayjs(date)
      );

      // Check if exception dates are within start and end date
      const isWithinRange = exceptionDates.every(
        (date) => date >= startDate && date <= endDate
      );

      return isWithinRange;
    },
    {
      message: "Exception dates must be within the start and end date.",
      path: ["exceptionDates"],
    }
  ) // Validate the exception dates are all on the correct day of week
  .refine(
    (data) => {
      const exceptionDates = data.exceptionDates.map((date: string) =>
        dayjs(date)
      );
      const isMatchingDayOfWeek = exceptionDates.every(
        (date) => date.day() === data.dayOfWeek
      );
      return isMatchingDayOfWeek;
    },
    {
      message: "Exception dates must match the day of week.",
      path: ["exceptionDates"],
    }
  )
  .refine(
    (data) => {
      const startDate = dayjs(data.startDate);
      const endDate = dayjs(data.endDate);
      const exceptionDates = data.exceptionDates.map((date: string) =>
        dayjs(date)
      );
      const totalWeeks = endDate.diff(startDate, "week") + 1;
      const exceptionWeeks = exceptionDates.length;
      return totalWeeks - exceptionWeeks === data.numberOfWeeks;
    },
    {
      message: `Number of weeks is not valid.`,
      path: ["numberOfWeeks"],
    }
  );

type SessionFormValues = z.infer<typeof sessionSchema>;

const initialSession = {
  id: undefined,
  setCapacity: 0,
  dayOfWeek: 0,
  endDate: "",
  exceptionDates: [],
  indyTeams: 0,
  leagueId: 0,
  maxCapacity: 0,
  priceForIndy: 0,
  numberOfWeeks: 0,
  priceForTeam: 0,
  registrationBatchId: 0,
  sessionNotes: "",
  startDate: "",
  startTimes: "",
  weeksOfPlayoffs: 0,
  venues: [],
  regSkillChoices: [],
};

const initialRegDates = {
  regOpenDate: "",
  regCloseDate: "",
};

const CreateSession: React.FC = () => {
  const params = useParams();

  const rootUrl =
    process.env.REACT_APP_ENV === "production"
      ? "https://jamsports.com"
      : process.env.REACT_APP_ENV === "staging"
      ? "https://jamitall.com"
      : "http://localhost:4001";

  const {
    isLoading,
    registrationBatches,
    leagues,
    selectedRegions,
    sports,
    regSkillChoices: regSkillChoicesState,
  }: any = useSelector((state: RootState) => state.venueMaster);

  const dispatch = useDispatch<AppDispatch>();
  const [newSession, setNewSession] =
    useState<SessionFormValues>(initialSession);
  const [sessionErrors, setSessionErrors] = useState<{
    [key: string]: string;
  }>({
    setCapacity: "",
    dayOfWeek: "",
    endDate: "",
    exceptionDates: "",
    indyTeams: "",
    leagueId: "",
    maxCapacity: "",
    priceForIndy: "",
    numberOfWeeks: "",
    priceForTeam: "",
    registrationBatchId: "",
    sessionNotes: "",
    startDate: "",
    startTimes: "",
    weeksOfPlayoffs: "",
    venues: "",
    regSkillChoices: "",
  });
  const [sessionErrorMessages, setSessionErrorMessages] = useState<string[]>(
    []
  );
  const [venues, setVenues] = React.useState<{ id: number; name: string }[]>(
    []
  );
  const [regDates, setRegDates] = React.useState<any>(initialRegDates);
  const [region, setRegion] = React.useState<any>(null);
  const [sport, setSport] = React.useState<any>(null);

  const [CreateSession, { loading: loadingCreate }] =
    useSessionCreateMutation();
  const [UpdateSession, { loading: loadingUpdate }] =
    useSessionUpdateMutation();
  const navigate = useNavigate();

  const { loading: loadingSession } = useSessionQuery({
    variables: {
      sessionId: +params.id!,
    },
    skip: params.id === undefined,
    onCompleted: (data) => {
      console.log(data.session);
      setNewSession({
        id: data.session.id,
        setCapacity: data.session.setCapacity,
        dayOfWeek: data.session.dayOfWeek,
        endDate: data.session.endDate,
        exceptionDates: data.session.exceptionDates,
        indyTeams: data.session.indyTeams,
        leagueId: data.session.league.id,
        maxCapacity: data.session.maxCapacity,
        priceForIndy: data.session.priceForIndy,
        numberOfWeeks: data.session.numberOfWeeks,
        priceForTeam: data.session.priceForTeam,
        registrationBatchId: data.session.registrationBatch.id,
        sessionNotes: data.session.sessionNotes,
        startDate: data.session.startDate,
        startTimes: data.session.startTimes,
        weeksOfPlayoffs: data.session.weeksOfPlayoffs,
        venues: data.session.venues.map((venue) => +venue.id),
        regSkillChoices: data.session.regSkillChoices.map(
          (regSkillChoice) => regSkillChoice.id
        ),
      });
      data.session.league.venues.map((venue) => console.log(+venue.id));
      setVenues(
        data.session.league.venues.map((venue) => {
          return {
            id: +venue.id,
            name: venue.name,
          };
        })
      );
      const registrationBatch = registrationBatches.find(
        (rb: RegistrationBatch) => data.session.registrationBatch.id === rb.id
      );
      if (registrationBatch) {
        setRegDates({
          regOpenDate: registrationBatch.regOpenDate,
          regCloseDate: registrationBatch.regCloseDate,
        });
      }
    },
  });

  useEffect(() => {
    dispatch(getRegistrationBatch(""));
    dispatch(getLeagues(""));
    dispatch(getSports(""));
    dispatch(getRegSkillChoices(""));
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSessionErrorMessages([]);
    const result = sessionSchema.safeParse(newSession);
    if (result.success) {
      // Validate the dates are all on the same day
      const { id: sessionId, ...sessionInput } = newSession;
      sessionInput.startDate = dayjs(newSession?.startDate).format(
        "YYYY-MM-DD"
      );
      sessionInput.endDate = dayjs(newSession.endDate).format("YYYY-MM-DD");
      if (!sessionId) {
        CreateSession({
          variables: { sessionArgs: { sessionInput } },
          refetchQueries: [ListAllOperations.Query.Session],
          onCompleted: (data) => {
            if (data.sessionCreate.success) {
              dispatch(displayAlertSuccess(data.sessionCreate.message));
              navigate(`/ops/sessions`);
            } else {
              dispatch(displayAlertError(data.sessionCreate.message));
            }
          },
          onError: (error) => {
            dispatch(
              displayAlertError(
                `Sessions: Something Went Wrong. Error: ${error.message}`
              )
            );
          },
        });
      } else {
        UpdateSession({
          variables: {
            sessionArgs: { id: sessionId, sessionInput: sessionInput },
          },
          refetchQueries: [ListAllOperations.Query.Session],
          onCompleted: (data) => {
            if (data.sessionUpdate.success) {
              dispatch(displayAlertSuccess(data.sessionUpdate.message));
              navigate(`/ops/sessions`);
            } else {
              dispatch(displayAlertError(data.sessionUpdate.message));
            }
          },
          onError: (error) => {
            dispatch(
              displayAlertError(
                `Sessions: Something Went Wrong. Error: ${error.message}`
              )
            );
          },
        });
      }
    } else {
      const newFormErrors = { ...sessionErrors };
      const newFormErrorKeys = Object.keys(newFormErrors);
      let messages: string[] = [];
      newFormErrorKeys.forEach((field) => {
        const fieldError = result.error.errors.find(
          (error) => error.path[0] === field
        );
        if (fieldError) {
          newFormErrors[field] = fieldError.message; // Set the error message
          messages.push(`Error ${field}: ${fieldError.message}`);
        } else {
          newFormErrors[field] = ""; // No matching error, set to an empty string
        }
      });

      dispatch(
        displayAlertError(
          `An Error occurred when ${
            newSession.id ? "updating" : "creating"
          } the session.`
        )
      );
      setSessionErrorMessages(messages);
      setSessionErrors(newFormErrors);
    }
  };

  const pathsBreadcrumbs = [
    { name: "Sessions", url: "/ops/sessions" },
    { name: "Session Details", url: "/ops/sessions" },
  ];

  if (loadingCreate || loadingUpdate || loadingSession || isLoading)
    return <LoadingDialog open={true} />;

  return (
    <main>
      {sessionErrorMessages.length > 0 && (
        <div className="flex flex-col gap-2">
          {sessionErrorMessages.map((errorMessage) => {
            return (
              <Alert
                variant="error"
                size="large"
                content={errorMessage}
                persist={true}
              />
              // <div className='p-2 border bg-error-95 border-error-30 rounded-xl w-fit'>
              //   {errorMessage}
              // </div>
            );
          })}
        </div>
      )}
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/sessions"
      ></BreadCrumbs>

      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 pb-16"
      >
        <div className="flex items-center gap-2">
          <Headline1Variable>
            {newSession.id ? "Edit Session" : "Create New Session"}
          </Headline1Variable>
          {newSession.id && (
            <Tooltip
              title="League Preview"
              arrow
            >
              <a
                className="text-lg text-gray-500 cursor-pointer"
                href={`${rootUrl}/program-discovery/league-details/${newSession.leagueId}`}
                target="_blank"
              >
                <VisibilityIcon />
              </a>
            </Tooltip>
          )}
        </div>
        {!newSession.id && (
          <Card>
            <Subtitle1>League Filters</Subtitle1>
            <div className="flex flex-row gap-4">
              <div className="w-fit">
                <FormFieldSelect
                  className="w-36"
                  inputChange={(value: string) => {
                    setRegion(value);
                  }}
                  label="Region"
                  placeholder="Region"
                  value={region}
                >
                  {selectedRegions.length > 0
                    ? [...[{ id: 0, name: "All" }], ...selectedRegions]
                    : [...[{ id: 0, name: "None" }]]}
                </FormFieldSelect>
              </div>
              <div className="w-fit">
                <FormFieldSelect
                  className="w-36"
                  inputChange={(value: string) => {
                    setSport(value);
                  }}
                  label="Sport"
                  placeholder="Sport"
                  value={sport}
                >
                  {[...[{ id: 0, name: "All" }], ...sports]}
                </FormFieldSelect>
              </div>
            </div>
          </Card>
        )}

        <Card>
          <Subtitle1>Session Details</Subtitle1>
          <div className="flex flex-row gap-4">
            <div className="w-fit">
              <FormFieldSelect
                className="w-[420px]"
                inputChange={(value: string) => {
                  const league = leagues.find((league: any) => {
                    return league.id === +value;
                  });
                  if (league && league.venues) setVenues(league.venues);
                  if (league && league.sportFormat)
                    setNewSession({
                      ...newSession,
                      weeksOfPlayoffs: league.sportFormat.weeksOfPlayOffs,
                      leagueId: +value,
                    });
                }}
                label="League"
                placeholder="League"
                value={newSession.leagueId?.toString() ?? "0"}
                error={sessionErrors["leagueId"] ? true : false}
              >
                {[
                  ...leagues.filter((league: any) => {
                    if (sport && region) {
                      const sportBool =
                        sport == 0
                          ? true
                          : league.sportFormat.sport.id == sport;
                      const regionBool =
                        region == 0
                          ? true
                          : league.sportFormat.region.id == region;
                      return sportBool && regionBool;
                    }
                    if (sport) {
                      return sport == 0
                        ? true
                        : league.sportFormat.sport.id == sport;
                    } else if (region) {
                      return region == 0
                        ? true
                        : league.sportFormat.region.id == region;
                    } else {
                      return true;
                    }
                  }),
                  ...[{ id: "0", name: "Select League" }],
                ]}
              </FormFieldSelect>
            </div>
            <div className="w-fit">
              <FormFieldSelect
                className="w-36"
                inputChange={(value: string) => {
                  setNewSession({
                    ...newSession,
                    dayOfWeek: +value,
                  });
                }}
                label="Day Of Week"
                placeholder="Day Of Week"
                value={newSession.dayOfWeek?.toString() ?? "0"}
                error={sessionErrors["dayOfWeek"] ? true : false}
              >
                {[
                  { id: 0, name: "Sunday" },
                  { id: 1, name: "Monday" },
                  { id: 2, name: "Tuesday" },
                  { id: 3, name: "Wednesday" },
                  { id: 4, name: "Thursday" },
                  { id: 5, name: "Friday" },
                  { id: 6, name: "Saturday" },
                ]}
              </FormFieldSelect>
            </div>
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Total Weeks"
                className="w-28"
                initialValue={newSession.numberOfWeeks?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    numberOfWeeks: +event.target.value,
                  });
                }}
                error={sessionErrors["numberOfWeeks"] ? true : false}
              />
            </div>
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Playoff Weeks"
                className="w-28"
                initialValue={newSession.weeksOfPlayoffs?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    weeksOfPlayoffs: +event.target.value,
                  });
                }}
                error={sessionErrors["weeksOfPlayoffs"] ? true : false}
              />
            </div>
            <FormFieldDate
              label="Start Date"
              className="w-40 whitespace-nowrap"
              initialValue={
                newSession.startDate !== ""
                  ? dayjs(newSession.startDate).toDate()
                  : undefined
              }
              dateChange={(date) => {
                setNewSession((prevState) => {
                  return {
                    ...prevState,
                    startDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              }}
              error={sessionErrors["startDate"] ? true : false}
            />
            <FormFieldDate
              label="End Date"
              className="w-40 whitespace-nowrap"
              initialValue={
                newSession.endDate !== ""
                  ? dayjs(newSession.endDate).toDate()
                  : undefined
              }
              dateChange={(date) => {
                setNewSession((prevState) => {
                  return {
                    ...prevState,
                    endDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              }}
              error={sessionErrors["endDate"] ? true : false}
            />
            <div className="flex flex-col whitespace-nowrap">
              <label className="px-3 text-xs font-medium">
                Exception Dates
              </label>
              <MultipleDatePicker
                multiple
                plugins={[<DatePanel />]}
                inputClass={classes["multi-DatePicker-input"]}
                containerClassName={classes["multi-DatePicker-container"]}
                value={newSession.exceptionDates}
                name="Exception Dates"
                onChange={(selectedDates: DateObject[]) => {
                  if (Array.isArray(selectedDates)) {
                    const dates = selectedDates?.map((date) => {
                      return dayjs(date.format()).format("YYYY-MM-DD");
                    });
                    setNewSession((prevState) => {
                      return {
                        ...prevState,
                        exceptionDates: dates,
                      };
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="flex flex-row gap-4 mt-3">
            <div className="w-fit">
              <FormFieldSelect
                className="w-80"
                inputChange={(value: string) => {
                  const registrationBatch = registrationBatches.find(
                    (rb: RegistrationBatch) => +value === rb.id
                  );
                  if (registrationBatch) {
                    setNewSession({
                      ...newSession,
                      registrationBatchId: +value,
                    });
                    setRegDates({
                      regOpenDate: registrationBatch.regOpenDate,
                      regCloseDate: registrationBatch.regCloseDate,
                    });
                  }
                }}
                label="Season"
                placeholder="Season"
                value={newSession.registrationBatchId?.toString() ?? "0"}
                error={sessionErrors["registrationBatchId"] ? true : false}
              >
                {[
                  ...registrationBatches,
                  ...[{ id: "0", name: "Select Season" }],
                ]}
              </FormFieldSelect>
            </div>
            <div className="w-40">
              <FormFieldDate
                label="Reg Open Date"
                className="w-40 whitespace-nowrap"
                initialValue={
                  regDates.regOpenDate !== ""
                    ? dayjs(regDates.regOpenDate).toDate()
                    : undefined
                }
                dateChange={(date) => {
                  setRegDates((prevState: any) => {
                    return {
                      ...prevState,
                      regOpenDate: dayjs(date).format("YYYY-MM-DD"),
                    };
                  });
                }}
                error={sessionErrors["regOpenDate"] ? true : false}
              />
            </div>
            <div className="w-40">
              <FormFieldDate
                label="Reg Close Date"
                className="w-40 whitespace-nowrap"
                initialValue={
                  regDates.regCloseDate !== ""
                    ? dayjs(regDates.regCloseDate).toDate()
                    : undefined
                }
                dateChange={(date) => {
                  setRegDates((prevState: any) => {
                    return {
                      ...prevState,
                      regCloseDate: dayjs(date).format("YYYY-MM-DD"),
                    };
                  });
                }}
                error={sessionErrors["regCloseDate"] ? true : false}
              />
            </div>
            <FormField
              label="Start Times"
              initialValue={newSession.startTimes ?? ""}
              inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewSession({
                  ...newSession,
                  startTimes: event.target.value,
                });
              }}
              error={sessionErrors["startTimes"] ? true : false}
            />
          </div>
          <div className="flex flex-col w-1/3 gap-4 mt-3">
            <FormFieldSelectMulti
              name="regSkillChoice"
              input={regSkillChoicesState}
              addAllOption={false}
              selectedItems={newSession.regSkillChoices.map(Number)}
              setSelectedItems={(values) => {
                setNewSession((prevState) => ({
                  ...prevState,
                  regSkillChoices: values,
                }));
              }}
              label="Skill Choice"
              placeholder="Skill Choice"
            />
            <div className="flex flex-row flex-wrap items-center justify-start w-full gap-2">
              {newSession.regSkillChoices &&
                newSession.regSkillChoices.length > 0 &&
                newSession.regSkillChoices.map((id: number) => {
                  const regSkillChoice = regSkillChoicesState.find(
                    (regSkill: any) => regSkill.id == id
                  );
                  return (
                    <FilterChip
                      key={id}
                      text={regSkillChoice ? regSkillChoice.name : "Oops"}
                      onDelete={() => {
                        const values = newSession.regSkillChoices.filter(
                          (regSkillId) => regSkillId != id
                        );
                        setNewSession({
                          ...newSession,
                          regSkillChoices: values,
                        });
                      }}
                    />
                  );
                })}
            </div>
          </div>
        </Card>
        <Card>
          <Subtitle1>Pricing</Subtitle1>
          <div className="flex flex-row gap-4">
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Team Price"
                className="w-28"
                initialValue={newSession.priceForTeam?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    priceForTeam: +event.target.value,
                  });
                }}
                error={sessionErrors["priceForTeam"] ? true : false}
              />
            </div>
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Free Agent Price"
                className="w-28"
                initialValue={newSession.priceForIndy?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    priceForIndy: +event.target.value,
                  });
                }}
                error={sessionErrors["priceForIndy"] ? true : false}
              />
            </div>
          </div>
        </Card>
        <Card>
          <Subtitle1>Capacity</Subtitle1>
          <div className="flex flex-row gap-4">
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Max Capacity"
                className="w-28"
                initialValue={newSession.maxCapacity?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    maxCapacity: +event.target.value,
                  });
                }}
                error={sessionErrors["maxCapacity"] ? true : false}
              />
            </div>
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Available Capacity"
                className="w-28"
                initialValue={newSession.setCapacity?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    setCapacity: +event.target.value,
                  });
                }}
                error={sessionErrors["setCapacity"] ? true : false}
              />
            </div>
            <div className="w-fit whitespace-nowrap">
              <FormField
                type="number"
                label="Indy Teams"
                className="w-28"
                initialValue={newSession.indyTeams?.toString() ?? "0"}
                inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewSession({
                    ...newSession,
                    indyTeams: +event.target.value,
                  });
                }}
                error={sessionErrors["indyTeams"] ? true : false}
              />
            </div>
          </div>
        </Card>
        <Card>
          <Subtitle1>Session Notes</Subtitle1>
          <div className="flex flex-row gap-4">
            <TextArea
              className="mb-4"
              name="Session Notes"
              label="Session Notes"
              inputChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNewSession({
                  ...newSession,
                  sessionNotes: event.target.value,
                })
              }
              value={newSession.sessionNotes}
              initialValue={newSession.sessionNotes ?? ""}
              rows={8}
              cols={50}
              error={sessionErrors["sessionNotes"] ? true : false}
            ></TextArea>
          </div>
        </Card>
        <Card>
          <Subtitle1>League Venues</Subtitle1>
          <div className="flex flex-col gap-4">
            {newSession.venues.map((venue: number, index: number) => {
              return (
                <div className="flex flex-row gap-4 max-w-72">
                  {/* Chnge to multi */}
                  <FormFieldSelect
                    key={index}
                    className="w-60"
                    inputChange={(value: string) => {
                      const eventClone: number[] = [...newSession.venues];
                      eventClone[index] = +value;
                      setNewSession({
                        ...newSession,
                        venues: eventClone,
                      });
                    }}
                    placeholder="Venue"
                    value={venue ? venue.toString() : "0"}
                    error={sessionErrors["venues"] ? true : false}
                  >
                    {[...venues, { id: "0", name: "Select Venue" }]}
                  </FormFieldSelect>
                  {newSession.venues.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        const newVenueClone = [...newSession.venues];
                        newVenueClone.splice(index, 1);
                        setNewSession({
                          ...newSession,
                          venues: newVenueClone,
                        });
                      }}
                    >
                      <HighlightOffRoundedIcon />
                    </IconButton>
                  )}
                </div>
              );
            })}
            <Button
              variant="secondary"
              className="max-w-40"
              type="button"
              onClick={() => {
                setNewSession({
                  ...newSession,
                  venues: [...newSession.venues, 0],
                });
              }}
            >
              Add Venue
            </Button>
          </div>
        </Card>
        <div className="flex flex-row gap-4">
          <Button
            variant="primary"
            type="submit"
          >
            {newSession.id ? "Update Session" : "Create Session"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => navigate("/ops/sessions")}
          >
            Cancel
          </Button>
        </div>
      </form>
    </main>
  );
};

export default CreateSession;
